import React, { useContext } from "react"
import CartContext from "../../context/CartProvider"
import CustomerContext from "../../context/CustomerProvider"

const AddToCart = ({ variant, quantity }) => {
  const customer_value = useContext(CustomerContext)
  const customerId = customer_value?.customer?.id || 0

  const value = useContext(CartContext)
  const addToCart = value && value.addToCart
  const addingToCart = value && value.state.addingToCart

  const disabledBtn =
    addingToCart === variant?.product_id ||
    !variant ||
    variant?.inventory_level === 0

  const formatBtnLabel = () => {
    let str = "Add To Cart"
    if (variant && addingToCart === variant?.product_id) {
      str = "Adding to Cart"
    }

    return str
  }

  return (
    <>
      <button
        type="button"
        className="btn btn-dark"
        disabled={disabledBtn}
        onClick={() =>
          addToCart(variant?.product_id, variant?.id, quantity, customerId)
        }
      >
        {formatBtnLabel()}
      </button>
    </>
  )
}

export default AddToCart
