import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"
import AccordionContext from "react-bootstrap/AccordionContext"
import { Accordion, useAccordionToggle, Card } from "react-bootstrap/"
import marked from "marked"
import RelatedItems from "../components/redesign/relatedItems"
import getYouTubeID from "get-youtube-id"
// import ModalVideo from "react-modal-video"
import loadable from "@loadable/component"
// redesign
import "@components/redesign/redesign-product.scss"
import BlockContent from "@components/block/block"
import { Container, Row, Col } from "reactstrap"
import CurrencyFormatter from "../components/bigcommerce/CurrencyFormatter"
import AddToCart from "../components/bigcommerce/AddToCart"
import ProductOptions from "../components/bigcommerce/ProductOptions"

const ModalVideo = loadable(() => import("react-modal-video"))

function CustomToggle({ children, eventKey, callback }) {
  const currentEventKey = React.useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey
  return (
    <button
      type="button"
      className={`accordion-label btn btn-link ${
        isCurrentEventKey ? "active" : ""
      }`}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  )
}

function ProductAccordion(props) {
  const { specs, accessories, alldownload, order, labels } = props

  return (
    <div className="product-accordion-content">
      <Accordion>
        {props.specs.length !== 0 ? (
          <Card>
            <Card.Header>
              <CustomToggle eventKey="specs">
                {labels && labels.quickSpecs
                  ? labels.quickSpecs
                  : "Quick Specs"}
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="specs">
              <Card.Body>
                {specs.length !== 0 &&
                  specs.map(spec => (
                    <div className="row" key={spec.key}>
                      <div className="col-4">
                        <strong>{spec.title}</strong>
                      </div>
                      <div className="col-8">{spec.labelInfo}</div>
                    </div>
                  ))}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ) : null}
        {props.order.length !== 0 ? (
          <Card>
            <Card.Header>
              <CustomToggle eventKey="order">
                {labels && labels.orderInfo ? labels.orderInfo : "Order"}
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="order">
              <Card.Body>
                {order.map(ord => (
                  <div className="row" key={ord._key}>
                    <div className="col-4">
                      <strong>{ord.title}</strong>
                      {ord.limited ? (
                        <span className="d-block font-weight-bold text-primary">
                          Limited Quantities Available
                        </span>
                      ) : null}
                    </div>
                    <div
                      className="col-8"
                      dangerouslySetInnerHTML={{
                        __html: marked((ord && ord.body) || ""),
                      }}
                    />
                  </div>
                ))}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ) : null}
        {props.accessories.length !== 0 ? (
          <Card>
            <Card.Header>
              <CustomToggle eventKey="accessories">
                {labels && labels.accessory ? labels.accessory : "Accessories"}
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="accessories">
              <Card.Body>
                {accessories.map(accs => (
                  <div className="row" key={accs._key}>
                    <div className="col-4">
                      <strong>{accs.itemNumber}</strong>
                      {accs.limited ? (
                        <span className="d-block font-weight-bold text-primary">
                          Limited Quantities Available
                        </span>
                      ) : null}
                    </div>
                    <div className="col-8">{accs.description}</div>
                  </div>
                ))}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ) : null}
        {props.alldownload.length !== 0 ? (
          <Card>
            <Card.Header>
              <CustomToggle eventKey="alldownload">
                {labels && labels.allDownload
                  ? labels.allDownload
                  : "All Downloads"}
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="alldownload">
              <Card.Body>
                {alldownload.map(dwnld => (
                  <div className="row resources" key={dwnld._key}>
                    <div className="col-12">
                      <strong>{dwnld.title}</strong>
                      {dwnld.pdfinfo.map(info => (
                        <div className="d-block" key={info._key}>
                          {info.file || info.url ? (
                            <a
                              href={info.url ? info.url : info.file.asset.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {info.title}
                            </a>
                          ) : (
                            <p>{info.title}</p>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ) : null}
      </Accordion>
    </div>
  )
}

const ProductPageSplashTemplate = props => {
  const [isOpen, setOpen] = React.useState(false)
  const [bcProduct, setBcProduct] = React.useState(null)
  const [bcProductVariant, setBcProductVariant] = React.useState(null)
  const [bcProductQty, setBcProductQty] = React.useState(1)
  const [loading, setLoading] = React.useState(true)

  const bigcommerce_id =
    props.data.allBigCommerceProducts?.nodes[0]?.bigcommerce_id
  React.useEffect(() => {
    if (bigcommerce_id) {
      fetch(
        `/.netlify/functions/bigcommerce?endpoint=/v3/catalog/products/${bigcommerce_id}?include=variants,options`
      )
        .then(res => res.json())
        .then(({ data }) => {
          setBcProduct(data)
          setBcProductVariant(data.variants[0])
          console.log("here", data)
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [bigcommerce_id])

  const openModal = () => {
    setOpen(true)
  }

  const carousel = {
    slidesPerView: 1,
    spaceBetween: 0,
    autoplay: true,
    loop: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
  }

  const product = props.data.sanityProduct
  const labels = props.data.allSanityLabel.edges.map(label => label.node)

  const siteDescription = product.shortdesc
    ? product.shortdesc.substring(0, 120)
    : props.data.site.siteMetadata.description

  return (
    <Layout location={props.location} title={product.title}>
      <SEO title={product.title} description={siteDescription} />
      <Container>
        {" "}
        <Row>
          <Col md={product.templates !== "template_a" ? 12 : 6}>
            <div className="product-swiper-section">
              <div className="container">
                <LazyLoadComponent>
                  {product.productImage.length !== 0 ? (
                    <div
                      className="swiper-product-wrap"
                      style={{
                        marginTop:
                          product.templates !== "template_a" ? 0 : "158px",
                      }}
                    >
                      {product.productImage.length === 1 ? (
                        <>
                          {product.productImage.map(prdctImg => (
                            <div className="product-slide" key={prdctImg._key}>
                              <img
                                src={
                                  prdctImg &&
                                  prdctImg.image &&
                                  prdctImg.image.asset &&
                                  prdctImg.image.asset.fluid &&
                                  prdctImg.image.asset.fluid.src
                                }
                                alt={product.title}
                              />
                            </div>
                          ))}
                        </>
                      ) : (
                        <Swiper {...carousel}>
                          {product.productImage.map(prdctImg => (
                            <div className="product-slide" key={prdctImg._key}>
                              <img
                                src={
                                  prdctImg &&
                                  prdctImg.image &&
                                  prdctImg.image.asset &&
                                  prdctImg.image.asset.fluid &&
                                  prdctImg.image.asset.fluid.src
                                }
                                alt={product.title}
                              />
                            </div>
                          ))}
                        </Swiper>
                      )}
                    </div>
                  ) : null}
                </LazyLoadComponent>
              </div>
            </div>
          </Col>
          <Col md={product.templates !== "template_a" ? 12 : 6}>
            <div
              className={`product-info-wrapper ${
                product.templates !== "template_a" ? "template_b" : "template_a"
              }`}
            >
              <div className="container">
                <h1 className="product-title">{product.title}</h1>
                {loading ? (
                  <p>Retrieving price..</p>
                ) : (
                  <div className="mb-3">
                    <h1 className="mb-0">
                      <CurrencyFormatter
                        amount={
                          bcProductVariant?.calculated_price ||
                          bcProduct?.calculated_price ||
                          0
                        }
                      />
                    </h1>
                    <small className="text-muted">
                      {bcProductVariant?.sku || bcProduct?.sku}
                    </small>
                  </div>
                )}

                <div className="row product-info-row justify-content-between">
                  <Col md={product.templates !== "template_a" ? 6 : 12}>
                    <div className="product-page-description">
                      {product._rawLongDesc && (
                        <BlockContent blocks={product._rawLongDesc} />
                      )}
                      {product._rawProdDetails && (
                        <BlockContent blocks={product._rawProdDetails} />
                      )}
                    </div>
                  </Col>
                  <Col
                    md={product.templates !== "template_a" ? 6 : 12}
                    className="product-accordion-col"
                  >
                    <div className="row">
                      <div className="col-12 order-2 order-md-1">
                        <div className="product-accordion-wrapper mt-4 mt-md-0">
                          <ProductAccordion
                            specs={product.qLabels}
                            accessories={product.accessories}
                            alldownload={product.alldownload}
                            order={product.orderingInfo}
                            labels={labels[0]}
                          />
                        </div>
                      </div>
                      {product.videoInfo.length !== 0 ? (
                        <div className="col-12 order-1 order-md-2">
                          <div className="product-video-buttons-wrapper">
                            {product.videoInfo.map(vid => {
                              const videoId = getYouTubeID(vid.videoUrl)
                              return (
                                <div key={vid._key} className="d-block">
                                  <button
                                    className="product-video-button"
                                    onClick={openModal}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src="/img/play-button.svg"
                                      className="play-btn"
                                      alt=""
                                    />
                                    {vid.videoTitle}
                                  </button>

                                  <ModalVideo
                                    channel="youtube"
                                    isOpen={isOpen}
                                    videoId={videoId}
                                    onClose={() => setOpen(false)}
                                  />
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </div>

                <div className="mt-3">
                  <ProductOptions
                    options={bcProduct?.options}
                    variant={bcProductVariant}
                    handleVariant={newVariant => {
                      const activeVariant = bcProduct.variants.find(
                        el =>
                          el.option_values.length === newVariant.length &&
                          el.option_values.every(function(element, index) {
                            return (
                              JSON.stringify(element) ===
                              JSON.stringify(newVariant[index])
                            )
                          })
                      )
                      setBcProductVariant(activeVariant)
                      console.log({ activeVariant })
                    }}
                  />
                  <div className="form-group w-100" style={{ maxWidth: 200 }}>
                    <label htmlFor="qty">Quantity</label>
                    <input
                      type="number"
                      className="form-control"
                      id="qty"
                      min={1}
                      value={bcProductQty}
                      onChange={e => setBcProductQty(e.target.value)}
                    />
                  </div>
                  {bcProductVariant?.inventory_level === 0 && (
                    <div className="text-center">
                      <div class="alert alert-danger" role="alert">
                        Out of Stock
                      </div>
                    </div>
                  )}
                  <AddToCart
                    variant={bcProductVariant}
                    quantity={bcProductQty}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {product.orderTable.length !== 0 && (
        <div className="secondary-specs-info-wrapper">
          <Container>
            <h4 className="product-page-heading">ORDERING INFORMATION</h4>
            <Row>
              <div className="col-12">
                <table
                  className="table d-inline-table overflow-x product-specs text-center"
                  style={{ overflowX: "auto" }}
                >
                  <thead>
                    <tr>
                      {product.orderTable[0].title.map(thead => (
                        <th scope="col">{thead.title.title}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {product.orderTable.map(table => (
                      <tr key={table._key}>
                        {table.title.length !== 0 &&
                          table.title.map(dat => (
                            <td key={dat._key} className="p-0">
                              {dat.title.title.toLowerCase() === "cct" ? (
                                <span
                                  className={`${dat.title.title.toLowerCase() ===
                                    "cct" &&
                                    dat.title.title.toLowerCase()}-${dat.labelInfo.toLowerCase()} pl-4`}
                                >
                                  {dat.labelInfo}
                                </span>
                              ) : (
                                dat.labelInfo
                              )}
                            </td>
                          ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Row>
          </Container>
        </div>
      )}

      {product.relatedProduct.length !== 0 ? (
        <LazyLoadComponent>
          <RelatedItems feature={product && product.relatedProduct} />
        </LazyLoadComponent>
      ) : null}
    </Layout>
  )
}

// class ProductPageSplashTemplate extends React.Component {
// 	render() {
// 		return <h1>test</h1>
// 	}
// }
export default ProductPageSplashTemplate

export const ProductPageSplashTemplateQuery = graphql`
  query ProductPageSplashTemplateQuery($id: String!, $bigcommerce_id: Int!) {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    sanityProduct(id: { eq: $id }) {
      id
      title
      shortdesc
      _rawLongDesc
      _rawProdDetails
      templates
      productImage {
        _key
        title
        image {
          asset {
            id
            fluid(maxWidth: 600, maxHeight: 600) {
              src
            }
          }
        }
      }
      orderTable {
        _key
        title {
          _key
          labelInfo
          title {
            title
          }
        }
      }

      qLabels {
        _key
        labelInfo
        title
      }
      orderingInfo {
        _key
        title
        body
        limited
      }
      accessories {
        _key
        itemNumber
        description
        limited
      }
      alldownload {
        _key
        title
        pdfinfo {
          _key
          title
          url
          file {
            asset {
              url
            }
          }
        }
      }
      videoInfo {
        _key
        videoTitle
        videoUrl
      }
      relatedProduct {
        id
        title
        slug {
          current
        }
        _rawShortdescrption
        newProduct
        discontinued
        shortdesc
        productImage {
          image {
            asset {
              fluid(maxWidth: 290) {
                src
              }
            }
          }
        }
      }
    }

    allSanityLabel {
      edges {
        node {
          id
          quickSpecs
          orderInfo
          allVideo
          allDownload
          accessory
        }
      }
    }
    allBigCommerceProducts(
      filter: { bigcommerce_id: { eq: $bigcommerce_id } }
    ) {
      nodes {
        id
        bigcommerce_id
        name
        custom_url {
          url
        }
      }
    }
  }
`
