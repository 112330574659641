import React from "react"

const line = {
  position: "absolute",
  width: 40,
  height: 3,
  left: 0,
  top: 20,
  background: "black",
  transform: "rotate(-45deg)",
}

const ProductOptions = ({ options = [], variant, handleVariant }) => {
  function renderOptionType(option, isDisabled, selectedOption) {
    const values = {
      ...option,
      id: option.id,
      option_display_name: option.display_name,
      isDisabled,
      selectedOption,
      variant,
      handleVariant,
    }

    switch (option.type) {
      case "swatch":
        return <Swatch key={option.id} {...values} />
      case "radio_buttons":
        return <RadioButtons key={option.id} {...values} />
      case "rectangles":
        return <Rectangles key={option.id} {...values} />
      case "dropdown":
        return <Dropdown key={option.id} {...values} />
      default:
        break
    }
  }

  return (
    <div>
      {options
        .sort(el => el.sort_order)
        .map(option => {
          let isDisabled = false
          let selectedOption = false

          const current = variant?.option_values.find(
            el => el.option_id === option.id
          )

          if (current) {
            if (variant?.inventory_level === 0) {
              isDisabled = current.id
            }
            selectedOption = current.id
          }

          return renderOptionType(option, isDisabled, selectedOption)
        })}
    </div>
  )
}

const Swatch = ({
  id,
  option_display_name,
  display_name,
  option_values,
  isDisabled,
  selectedOption,
  variant,
  handleVariant,
}) => {
  function handleChange(option_value) {
    const payload = {
      id: option_value.id,
      label: option_value.label,
      option_id: id,
      option_display_name,
    }

    const newValue = variant.option_values.map(el => {
      if (el.option_id === id) {
        return payload
      }
      return el
    })
    handleVariant(newValue)
  }

  return (
    <div className="mb-3">
      <p className="mb-2">{display_name}</p>
      <div className="d-flex flex-wrap">
        {option_values
          .sort(el => el.sort_order)
          .map(option_value => (
            <div key={option_value.id} style={{ position: "relative" }}>
              {isDisabled === option_value.id && <div style={line} />}
              <div
                className="mr-2"
                style={{
                  backgroundColor: option_value.value_data.colors[0],
                  height: 40,
                  width: 40,
                  opacity: isDisabled === option_value.id ? 0.5 : 1,
                  cursor: isDisabled === option_value.id ? "" : "pointer",
                  borderStyle: "solid",
                  borderColor:
                    selectedOption === option_value.id
                      ? "black"
                      : "transparent",
                }}
                onClick={() => {
                  if (isDisabled === option_value.id) return
                  handleChange(option_value)
                }}
              />
            </div>
          ))}
      </div>
    </div>
  )
}

const RadioButtons = ({
  id,
  option_display_name,
  display_name,
  option_values,
  isDisabled,
  selectedOption,
  variant,
  handleVariant,
}) => {
  function handleChange({ target }) {
    const option_value = option_values.find(el => el.id === +target.value)

    const payload = {
      id: option_value.id,
      label: option_value.label,
      option_id: id,
      option_display_name,
    }

    const newValue = variant.option_values.map(el => {
      if (el.option_id === id) {
        return payload
      }
      return el
    })
    handleVariant(newValue)
  }

  return (
    <div className="mb-3">
      <p className="mb-2">{display_name}</p>
      {option_values
        .sort(el => el.sort_order)
        .map(option_value => (
          <div
            className="custom-control custom-radio pl-0"
            key={option_value.id}
          >
            <input
              type="radio"
              id={option_value.id}
              name={id}
              className="custom-control-input"
              disabled={isDisabled === option_value.id}
              checked={selectedOption === option_value.id}
              value={option_value.id}
              onChange={handleChange}
            />
            <label className="custom-control-label" htmlFor={option_value.id}>
              {option_value.label}
            </label>
          </div>
        ))}
    </div>
  )
}

const Rectangles = ({
  id,
  option_display_name,
  display_name,
  option_values,
  isDisabled,
  selectedOption,
  variant,
  handleVariant,
}) => {
  function handleChange(option_value) {
    const payload = {
      id: option_value.id,
      label: option_value.label,
      option_id: id,
      option_display_name,
    }

    const newValue = variant.option_values.map(el => {
      if (el.option_id === id) {
        return payload
      }
      return el
    })
    handleVariant(newValue)
  }

  return (
    <div className="mb-3">
      <p className="mb-2">{display_name}</p>
      <div className="d-flex flex-wrap">
        {option_values
          .sort(el => el.sort_order)
          .map(option_value => (
            <div key={option_value.id} style={{ position: "relative" }}>
              {isDisabled === option_value.id && <div style={line} />}
              <div
                className="mr-2 bg-light d-flex justify-content-center align-items-center"
                style={{
                  height: 40,
                  width: 40,
                  cursor: isDisabled === option_value.id ? "" : "pointer",
                  borderStyle: "solid",
                  borderColor:
                    selectedOption === option_value.id
                      ? "black"
                      : "transparent",
                }}
                onClick={() => {
                  if (isDisabled === option_value.id) return
                  handleChange(option_value)
                }}
              >
                {option_value.label}
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

const Dropdown = ({
  id,
  option_display_name,
  display_name,
  option_values,
  isDisabled,
  selectedOption,
  variant,
  handleVariant,
}) => {
  function handleChange({ target }) {
    const option_value = option_values.find(el => el.id === +target.value)

    const payload = {
      id: option_value.id,
      label: option_value.label,
      option_id: id,
      option_display_name,
    }

    const newValue = variant.option_values.map(el => {
      if (el.option_id === id) {
        return payload
      }
      return el
    })
    handleVariant(newValue)
  }

  return (
    <div className="mb-3">
      <p className="mb-2">{display_name}</p>
      <select
        className="form-control"
        id={id}
        value={selectedOption}
        onChange={handleChange}
      >
        {option_values
          .sort(el => el.sort_order)
          .map(option_value => (
            <option key={option_value.id} value={option_value.id}>
              {`${option_value.label} ${
                isDisabled === option_value.id ? "(Out of stock)" : ""
              }`}
            </option>
          ))}
      </select>
    </div>
  )
}

export default ProductOptions
