import React from "react"
import { Link } from "gatsby"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"
import BlockContent from "@components/block/block"
import slugify from "slugify"
class RelatedItems extends React.Component {
	render() {
		const params = {
			speed: 1000,
			autoplay: false,
			loop: false,
			pagination: {
				el: ".swiper-pagination",
				type: "bullets",
				clickable: true,
			},
			navigation: {
				nextEl: "#related-next",
				prevEl: "#related-prev",
			},
			breakpoints: {
				320: {
					slidesPerView: 1,
				},
				480: {
					slidesPerView: 2,
				},
				640: {
					slidesPerView: 3,
				},
				760: {
					slidesPerView: 4,
				},
			},
		}

		return (
			<div className="related-items-section">
				<div className="container">
					<h2 className="related-items-title">Related Items</h2>
				</div>
				<div className="container-fluid position-relative">
					<div className="related-items-swiper-wrapper">
						<div className="swiper-navigation">
							<div id="related-prev" className="swiper-prev">
								<img src="/img/swiper-prev.svg" alt="" />
							</div>
							<div id="related-next" className="swiper-next">
								<img src="/img/swiper-next.svg" alt="" />
							</div>
						</div>

						<LazyLoadComponent>
							<Swiper {...params}>
								{this.props.feature.map(feat => {
									const slug =
										feat.slug && feat.slug.current
											? feat.slug.current
											: slugify(feat.title)
									return (
										<div key={feat.id}>
											<Link to={`/${slug}`}>
												<div className="product-box">
													<div className="product-image-wrapper">
														{feat.newProduct ? (
															<span className="product-status">New</span>
														) : null}
														{feat.productImage.length !== 0 ? (
															<img
																src={feat.productImage[0].image.asset.fluid.src}
																alt=""
															/>
														) : null}
													</div>
													<h5 className="product-name text-body">
														{feat.title}
													</h5>
													{feat.discontinued ? (
														<p className="font-weight-bold mb-0">
															Discontinued
														</p>
													) : null}
													{feat._rawShortdescrption && (
														<div className="px-md-3 product-desc text-muted">
															<BlockContent blocks={feat._rawShortdescrption} />
														</div>
													)}
												</div>
											</Link>
										</div>
									)
								})}
							</Swiper>
						</LazyLoadComponent>
					</div>
				</div>
			</div>
		)
	}
}

export default RelatedItems
